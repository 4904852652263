import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Form, Input } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";


function ForgotPassword() {
  var token = localStorage.getItem("userid");
  const [loadingsplash, setloadingsplash] = useState(true);

  const user_id =localStorage.getItem('userid');
  const [otp, setOtp] = useState(false);
  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const otpboxshow = () => {
    setOtp(true);
  }

  sessionStorage.setItem("token", "");
  const [user, setUserts] = useState({
    mobile: "",
  });

  const [motp, setMotp] = useState(
    {
      mobile: "9782140792",
      otp: "123456"
    }
  )

  const [otpsand, setOtpsend] = useState(null);
  console.warn()
      // alert(otpsand)
      console.warn(otpsand)
  const [isShown, setIsShown] = useState(false);

  const [otpnumbers, otpnumber] = useState();
  //  alert(otpnumbers)
  const [loading, setLoading] = useState(false);
  const [password, setpassword] = useState('');
  const [isValidMobile, setIsValidMobile] = useState(false);
  const [mobile, mobilenumber] = useState("");

  function onInputChange(e) {
    const mobileNumber = e.target.value;
    mobilenumber(mobileNumber);
    const isValid = /^[0-9]{10}$/.test(mobileNumber);
    setIsValidMobile(isValid);
  };
  function onInputChangeotp(e) {
    otpnumber(e.target.value);
  };


  function onInputChangePassword(e) {
    setpassword(e.target.value);
  };

  const loginChk = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const url = `${process.env.REACT_APP_API_URL}/forget_password.php`;
      const formData = new FormData();
      var mobile_no=localStorage.getItem('mobile');
      formData.append('mobile', mobile_no);
      formData.append('otp', otpnumbers);
      formData.append('password', password);
      formData.append('app_id', process.env.REACT_APP_API_ID);
      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };
  
      const response = await axios(config);
      console.warn(response);
   
      if (response.data.success == 1) {
        localStorage.setItem('mobile', null);
        console.warn(response.data.success);
        Swal.fire({
          icon: "success",
          title: (response.data.message),
          timer: 2000,
        }).then((result) => {
          navigate('/');
          console.warn(response.data.device_id);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: (response.data.message),
          timer: 2000,
        });
        }
    } catch (error) {
      console.error('Error during API request:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let url = `${process.env.REACT_APP_API_URL}/forget_password.php `;

      const formData = new FormData();
      formData.append('mobile', mobile);
      formData.append('app_id', process.env.REACT_APP_API_ID);

      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          const res = JSON.stringify(response.data);
          console.warn(response.data);
          
          const objectRes = JSON.parse(res);
          console.log(objectRes);
          // alert(response.data.dev_id);
          if (response.data.success == 1) {
            localStorage.setItem('mobile', mobile);
            setIsShown(true);
          } else {
            setIsShown(false);
          }
        })
        .catch(function (error) {
          console.error('There was a problem with the fetch operation:', error);
          setIsShown(false);
        })
        .finally(() => {
          setTimeout(() => {
          setLoading(false);
        }, 1500);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setIsShown(false);
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const loginverfiy = async (e) => {

  }
 

  return (
    <>
       {/* <div className={`splash-screen ${loadingsplash ? "visible" : "hidden"}`}>
       <div class="logonew">
        <img src={Logoimg} className="img-fluid"/>
       </div>
       <div class="safe">
        <img src={safe} className="img-fluid"/>
       </div>
    </div>   */}
    {/* screen end */}
      <div className="Loginpage">
        <div className="loginform position-relative">
            <div className="logobgshape d-flex justify-content-center align-items-center ">
          <div className="logo ">
            {/* <img src={Logoimg} width="150" /> */}

          </div>
        </div>
          <div className="bg-white login-form">
            <h3 className="mb-0 fw-bold text-center">Welcome Back</h3>
            <div className="form-login-design">

              {/* {deviceId1}
            {appId1} */}
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  {!isShown ? (
                    <Col span={24} xl={24} md={24}>
                      <Form.Item

                        name="username" prefix={<UserOutlined className="site-form-item-icon" />}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Number!',
                          },
                        ]}
                      >
                        <div>
                          <small>Moblie Number</small>
                          <Input className="username" name="mobileNum" maxLength={10} placeholder="Mobile Number" value={mobile} onChange={onInputChange}
                            prefix={<UserOutlined className="site-form-item-icon" style={{ padding: "9px" }} />}
                          />
                          {/* {isValidMobile ? <p>Valid mobile number!</p> : <p>Invalid mobile number</p>} */}

                        </div>
                      </Form.Item>
                    </Col>
                  ) : null}
                  {/* {otp && ( */}

                  {isShown ? (
                    <Col span={24} xl={24} md={24}>
                      <Form.Item
                        name="number"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Otp!',
                          },
                        ]}
                      >

                        <Input style={{ padding: "9px" }}  value={otpnumbers} onChange={onInputChangeotp} placeholder="Enter OTP" />

                      </Form.Item>


                      <Form.Item
                        name="pass"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                      >

                        <Input style={{ padding: "9px" }}  value={password} onChange={onInputChangePassword} placeholder="New Password" />

                      </Form.Item>
                    </Col>

                    
                  ) : null}
                  {/* )} */}
                  <Col span={24} xl={24} md={24}>
                    <Form.Item
                      wrapperCol={{

                        span: 16,
                      }}
                    >
                      {/* <Link type="button" onClick={otpboxshow} className="bg-color-gradient btn_color_all w-100 d-block Loginbtn" htmlType="submit">
                        Submit
                      </Link> */}
                      {loading ? (
                        <div className="d-flex  position-relative loginloader" >
                          {/* <img src={loading1} className="px-2 loaderfile" style={{ width: '50px' }} alt="Loading..." /> */}
                        </div>
                      ) : isShown ? (
                        <button
                          type="button"
                          className="bg-green refer-button w-100 text-white btn-primary cxy btn otp_login submit_data"
                          onClick={loginChk}
                          id="submit_data"
                          name="submit_data"
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          onClick={onSubmit}
                          type="submit"
                          className={`w-100  refer-button cxy send-otp btn sendotp text-white ${isValidMobile ? 'valid-button' : ''
                            }`}
                          id="send_ottp"
                          disabled={!isValidMobile}
                        >
                          SEND OTP
                        </button>
                      )}

                    </Form.Item>
                  </Col>
                </Row>



              </Form>
              
            </div>
          </div>
        </div>

      </div>



    </>
  );
}

export default ForgotPassword;
