import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import profile from "../../assets/img/logo.png";
import { MdDelete } from "react-icons/md";
import Table from "react-bootstrap/Table";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

function Troplepana() {
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [gameid, setGameid] = useState(localStorage.getItem("gameid") || "");
  const [appmanagerdata, setAppmanager] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const [items, setItems] = useState([]);
  const [totalPoints, setTotalPoints] = useState();
  const [users, setUsers] = useState({
    remaining_time_in_seconds: 3600,
    betpoint_change_time: 3600,
  });
  const navigate = useNavigate();
  const [points, setPoints] = useState();
  const location = useLocation();
  const value = location.state && location.state.value;
  const market_name = localStorage.getItem("market_name");
  const [profiles, setProfiles] = useState([]);
  // const [gameid, setGameid] = useState("");
  const [currentDate, setCurrentDate] = useState(getFormattedDate());
  const user_id = localStorage.getItem("userid");
  const dev_id = localStorage.getItem("dev_id");
  const [amount, setAmount] = useState("");
  const [session, setSession] = useState("");
  const [OpenSession, setOpenSession] = useState("");
  const [OpenSessionNew, setOpenSessionNew] = useState(false);
  const [OpenSessionNewClose, setOpenSessionNewClose] = useState(false);
  const [selectedNumberBat, setselectedNumberBat] = useState("");
  const [opencloseBatSession, setopencloseBatSession] = useState("");

  // const [users, setUsers] = useState([]);

  const [error, setError] = useState("");
  const [num, setNumber] = useState([]);
  const [values, setValues] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const newGameid = queryParams.get("id");

    if (newGameid) {
      setGameid(newGameid);
      localStorage.setItem("gameid", newGameid);
    } else {
      const storedGameid = localStorage.getItem("gameid");
      if (storedGameid) {
        setGameid(storedGameid);
      }
    }
    chktime();
  }, []);
  const handleButtonClick = () => {
    if (!validateEnteredNumber() || !validatePoints()) {
      return;
    }
    if (selectedNumberBat != num) {
      toast.error("Digit Value Not valid!");
      return;
    }
    const newItem = {
      session: opencloseBatSession,
      digit: num,
      points: points,
    };
    setItems((prevItems) => [...prevItems, newItem]);
    setTotalPoints((prevTotal) => prevTotal + parseInt(points, 10));
    // Clear input fields
    // setNumber("");
    // setPoints("");
    // setShowTable(true);
    setNumber("");
    setPoints("");
    setValues([]);
  };
  const validateEnteredNumber = () => {
    const isValid = /^\d+$/.test(num);
    if (!isValid) {
      toast.error("Please enter a valid number");
    }
    return isValid;
  };

  const validatePoints = () => {
    const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
    if (!isValid) {
      toast.error("Minimum bet ₹ 10");
    }
    return isValid;
  };
  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    const deletedItem = updatedItems.splice(index, 1)[0];

    setTotalPoints((prevTotal) => prevTotal - parseInt(deletedItem.points, 10));

    setItems(updatedItems);
  };

  useEffect(() => {
    loadUser();
  }, [num]);
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }
  var checkedRadio = document.querySelector(
    'input[name="session_name"]:checked'
  );

  var game_session = checkedRadio
    ? checkedRadio.value
    : "default_session_value";

  const chktime = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    const gameid = localStorage.getItem("market_id");

    let url = `${process.env.REACT_APP_API_URL}/get_session.php`;
    const formData = new FormData();
    formData.append("market_id", gameid);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          setOpenSession(res["open_session"]);
          if (res["open_session"] == "open") {
            setOpenSessionNew(true);
            setopencloseBatSession("open");
          } else {
            // alert('pppp');
            //  $('#open').setAttribute('disabled',true);
            setOpenSessionNewClose(true);
            setopencloseBatSession("close");
          }
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };

  useEffect(() => {
    setCurrentDate(getFormattedDate());
  }, []);
  useEffect(() => {
    const url = new URL(window.location.href);
    const newGameid = url.searchParams.get("id");

    if (newGameid) {
      setGameid(newGameid);
      localStorage.setItem("gameid", newGameid);

      // alert(newGameid)
    }
  }, [window.location.href]);

  const loadUser = async () => {
    const userId = localStorage.getItem("userid");
    const devId = localStorage.getItem("dev_id");
    const gameid = localStorage.getItem("market_id");

    let url = `${process.env.REACT_APP_API_URL}/num_value.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", userId);
    formData.append("id", 5);
    formData.append("dev_id", devId);
    formData.append("number", num);
    formData.append("market_id", gameid);

    var config = {
      method: "POST",
      url: url,
      data: formData,
    };

    try {
      const response = await axios(config);
      const responseData = response.data.success[0];

      console.warn(responseData);

      setValues(responseData);

      if (responseData && responseData.message === "success") {
        // setValues(responseData.success);
      } else {
        console.error("Unexpected or undefined data in the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const gameid = localStorage.getItem("market_id");
    const dev_id = localStorage.getItem("dev_id");
    //  alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("market_id", gameid);
    formData.append("dev_id", dev_id);
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes);
      console.warn(objectRes);
    });
  };
  useEffect(() => {
    loaduser();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (users.remaining_time_in_seconds > 0) {
        setUsers((prevState) => ({
          ...prevState,
          remaining_time_in_seconds: prevState.remaining_time_in_seconds - 1,
        }));
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [users.remaining_time_in_seconds]);
  const formatTime = (users) => {
    const hrs = Math.floor(users.remaining_time_in_seconds / 3600);
    const mins = Math.floor((users.remaining_time_in_seconds % 3600) / 60);
    const secs = users.remaining_time_in_seconds % 60;
    const formattedHrs = hrs.toString().padStart(2, "0");
    const formattedMins = mins.toString().padStart(2, "0");
    const formattedSecs = secs.toString().padStart(2, "0");

    return `${formattedHrs} : ${formattedMins} : ${formattedSecs}`;
  };

  useEffect(() => {
    // alert('pppp');
    const timer = setInterval(() => {
      if (users.betpoint_change_time > 0) {
        setUsers((prevState) => ({
          ...prevState,
          betpoint_change_time: prevState.betpoint_change_time - 1,
        }));
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [users.betpoint_change_time]);

  const formatTime1 = (users) => {
    const hrs = Math.floor(users.betpoint_change_time / 3600);
    const mins = Math.floor((users.betpoint_change_time % 3600) / 60);
    const secs = users.betpoint_change_time % 60;
    const formattedHrs = hrs.toString().padStart(2, "0");
    const formattedMins = mins.toString().padStart(2, "0");
    const formattedSecs = secs.toString().padStart(2, "0");

    return `${formattedHrs} : ${formattedMins} : ${formattedSecs}`;
  };
  const playgamecrossing = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // const validatePoints = () => {
    //   const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
    //   return isValid;
    // };
    // if (!validatePoints()) {
    //   toast.error("Minimum bet ₹ 10");
    //   return;
    // }
    // if (parseInt(setminibet) > parseInt(points)) {
    //   toast.error(`Minimum Bet Placed ${appmanagerdata.jodi_min}`);
    //   return;
    // }
    // if (parseInt(MaxbetAmt) < parseInt(points)) {
    //   console.warn(MaxbetAmt);
    //   toast.error(`Maximum Bet Placed ${appmanagerdata.jodi_max}`);
    //   return;
    // }
    if (users.points < points) {
      toast.error(`You Dont Have Sufficient Balance`);
      return;
    }

    try {
      setLoadingbutton(true);

      const betList = items
        .map((item, index) => {
          return {
            betkey: item.digit,
            betamount: item.points,
            session_name: item.session,
            bettype: "5",
          };
        })
        .filter((item) => item.betamount > 0);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/play_game.php`,
        {
          user_id: user_id,
          dev_id: dev_id,
          market_id: gameid,
          app_id: process.env.REACT_APP_API_ID,
          BetList: betList,
          dev_model: "web",
          market_type: "kalyan",
          devName: "sumsang",
        }
      );

      setIsButtonVisible(true);
      if (response.data.success == 1) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2500,
        }).then((result) => {
          navigate("/");
        });
      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: "error",
        });
        return;
      }
      // setCurrentDate(getFormattedDate());

      // return;
    } catch (error) {
      console.error("Game store:", error);
      toast.error(
        "An error occurred while Game store. Please try again later."
      );
    } finally {
      setLoadingbutton(false);
    }
  };
  const totalpoint = items.reduce(
    (acc, item) => acc + parseInt(item.points, 10),
    0
  );
  const [show, toggleShow] = React.useState(false);

  useEffect(() => {
    Getprofile();
  }, []);

  const Getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const handelclick = () => {
    // alert('hhh')
    navigate(-1);
  };
  const onChangeCaptureHandler = (e) => {
    //  alert('ppp');
    setOpenSessionNew(false);
    setOpenSessionNewClose(true);
    setopencloseBatSession("close");
  };
  const onChangeCaptureHandler1 = (e) => {
    // alert('444');
    if (OpenSession == "close") {
      setOpenSessionNew(false);
      setOpenSessionNewClose(true);
      setopencloseBatSession("close");
    } else {
      setOpenSessionNewClose(false);
      setOpenSessionNew(true);
      setopencloseBatSession("open");
    }
  };
  const datavalue = (value) => {
    setValues("");
    setselectedNumberBat(value);
    // alert(value)
  };
  return (
    <>
      <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Link onClick={handelclick} class="arrowlink">
                <FaArrowLeft />
              </Link>
            </Col>
            <Col xs={8} md={8} s={8}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex">
                  <div class="headericonarrow"></div>
                  {/* {market_name} */}
                  Triple Pana
                </div>
              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center wallet">
                  <Icon.Wallet /> :{profiles.credit}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="containter">
        <section id="crossing">
          <div className="Add_bank-detail WithdrawAmt gali">
            <Container>
              <div className="inputbox">
                {OpenSessionNew == true ? (
                  <h3 className="bg-white count form-control">
                    {formatTime1(users)}
                  </h3>
                ) : (
                  <h3 className="bg-white count form-control">
                    {formatTime(users)}
                  </h3>
                )}
              </div>
              <div className="inputbox">
                {/* <Form.Label>Choose Date</Form.Label> */}
                <input
                  className="form-control"
                  value={currentDate}
                  placeholder="Withdraw"
                />
              </div>
              <div className="inputbox">
                <div className="Add_bank mt-0">
                  {/* <Form.Label>Choose Session</Form.Label> */}
                  <ul className="Payment_tabs">
                    <li>
                      <div className="tabs">
                        {OpenSessionNew}
                        <input
                          type="radio"
                          name="session_name"
                          id="open"
                          value="open"
                          checked={OpenSessionNew ? true : false}
                          onClick={onChangeCaptureHandler1}
                        />
                        <label className="text-dark">Open</label>
                      </div>
                    </li>
                    <li>
                      <div className="tabs">
                        <>
                          <input
                            type="radio"
                            name="session_name"
                            value="close"
                            id="close"
                            onChange={() => setSession("close")}
                            onClick={onChangeCaptureHandler}
                            checked={OpenSessionNewClose ? true : false}
                          />
                        </>
                        <label className="text-dark">Close</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-light mt-1">Panna</Form.Label>
                    <div className="w-100 position-relative">
                      <Form.Control
                        type="number"
                        placeholder="Enter Panna"
                        value={num}
                        className="points"
                        onChange={(e) => setNumber(e.target.value.slice(0, 3))}
                        onClick={() => toggleShow(!show || true)}
                      />

                      {show && (
                        <>
                          {values && values.length > 0 ? (
                            <ul className="Digit_list">
                              <li
                                onClick={() => {
                                  setNumber(values);
                                  datavalue(values);
                                  toggleShow(false); 
                                }}
                              >
                                {values}
                              </li>
                            </ul>
                          ) : null}
                        </>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-light mt-1">Point </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Point"
                      value={points}
                      className="points"
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedText = e.clipboardData
                          .getData("text/plain")
                          .replace(/[^0-9]/g, "")
                          .slice(0, 2);
                        document.execCommand("insertText", false, pastedText);
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setPoints(inputValue);
                      }}
                      required
                    />
                  </Form.Group>
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
              <div className="form-btn">
                {users.remaining_time_in_seconds > 0 ? (
                  <button className="btn-add w-100" onClick={handleButtonClick}>
                    Add
                  </button>
                ) : (
                  <button className="btn-addclose w-100">
                    Bet Has Been Closed
                  </button>
                )}
              </div>

              <table className="table tablecrossing mb-2 mt-2">
            {items.length > 0 ? (
              <thead>
                <tr>
                  <th>Session</th>  
                  <th>Digit</th>
                  <th>Points</th>
                  <th>Action</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.session}</td>
                  <td>{item.digit}</td>
                  <td>{item.points}</td>
                  <td
                    className="text-danger"
                    onClick={() => handleDeleteItem(index)}
                  >
                    <i className="bi bi-trash3"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            </Container>
            <div className="totalpoints d-flex justify-content-between align-items-center">
              <div className="d-flex bg-light justify-content-between align-items-center">
                <p className=" text-dark"> ₹</p>
                <p className="ms-1  text-black">{totalpoint} /-</p>
              </div>

              {users.remaining_time_in_seconds > 0 ? (
                <>
                  <button
                    className="btn-add"
                    onClick={playgamecrossing}
                    disabled={loadingbutton || items.length === 0}
                  >
                    <span>Submit</span>
                    {loadingbutton && <Spinner animation="border" />}
                  </button>
                </>
              ) : (
                <button className="btn-add">
                  <span>Bet Has Been Closed</span>
                </button>
              )}
            </div>
          </div>
          
          <ToastContainer />
        </section>
      </div>
    </>
  );
}

export default Troplepana;
