import React from 'react'
import logo from '../../assets/img/logo.jpg';



export default function Howtoplay() {
    return (
        <div className="wininghistory" id="howtoplay">
            <div className="user_profile">
                <div className="logoImg d-flex justify-content-center" >
                    <img style={{borderRadius:"30px"}} src={logo} alt="Logo" className="img-fluid" />
                </div>
                <div className="pagenotice">
                    <button className="text-center btn_play">Watch on youtube</button>
                  
                </div>
            </div>
        </div>
    )
}
